// Libs
import React from "react"
import StringReplace from 'react-string-replace';

// Assets
import PlayShadowIcon from "../../../assets/icons/play-shadow";
import IconRetweet from '../../../assets/images/combined-shape.svg';
import defaultUserIcon from "../../../assets/images/default_profile_normal.png"

// utils
import params from '../../../utils/params'
import GlobalUtils from '../../../utils/global'
import { HANDLE_REGEX } from "../../../utils/constants";

class FluxCardContent extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      open_graph: null,
      image_upload_care: null
    }

    this.handleMediaError = this.handleMediaError.bind(this)
  }

  async componentDidMount() {
    const post = this.props.post
    const network = this.props.account.network


    /** Fetch open graph */

    if (network === "twitter") {
      if (!post.quoted_status && !post.retweeted_status && post.entities && post.entities.urls && post.entities.urls.length) {
        let open_graph = await this.props.fetchOpenGraphAction(post.entities.urls[0].expanded_url)

        if (open_graph && !open_graph.error) this.setState({ open_graph }, () => this.props.handleResize())
      }
    }
    else if (network === "linkedin") {
      let linkedinPostContent = this.props.post.specificContent["com.linkedin.ugc.ShareContent"]

      // post contains link with preview
      if (linkedinPostContent.shareMediaCategory === "ARTICLE" && linkedinPostContent.media[0]) {

        let open_graph = await this.props.fetchOpenGraphAction(linkedinPostContent.media[0].originalUrl)
        if (open_graph && !open_graph.error) this.setState({ open_graph }, () => this.props.handleResize())

      }
    }
    else if (["instagram", 'facebook'].includes(network)) {
      if (post.image_uploadcare && !post.image_uploadcare.url && post.image_uploadcare.token) {
        let image_upload_care = await this.props.getUploadCareUrlAction({ id: this.props.id, token: post.image_uploadcare.token })

        if (image_upload_care && !image_upload_care.error) {
          this.setState({
            image_upload_care: image_upload_care && image_upload_care.url
          }, () => this.props.handleResize())
        }
      }
      if ((network === "facebook") && !post.video && post.link) {
        let open_graph = await this.props.fetchOpenGraphAction(post.link)
        if (open_graph && !open_graph.error) this.setState({ open_graph }, () => this.props.handleResize())
      }


    }
  }

  buildLink(message, url, j) {
    try {

      // Remove dot, comma and two points at the end of url
      if (url.endsWith(',') || url.endsWith('.') || url.endsWith(':')) url = url.slice(0, url.length - 1)

      return StringReplace(message, url, (match, i) => {

        let urlHasProtocol = url.startsWith("http://") || url.startsWith("https://")
        let urlObject = null
        let tldListIndex = -1

        try {
          urlObject = new URL(!urlHasProtocol ? "https://" + url : url)

          let tld = urlObject.hostname.split(".").pop()
          tldListIndex = GlobalUtils.binarySearch(tld, params.TLD_LIST)

        } catch (error) {
          //console.error({error, url})
          return url
        }

        // if url domain is unknow return plain text
        if (tldListIndex === -1) {
          return url
        }

        // Delete protocol
        let simplifiedUrl = `${urlObject.hostname.replace('www.', '')}${urlObject.pathname}`

        // Reduce length
        simplifiedUrl = simplifiedUrl.length > 26 ? `${simplifiedUrl.substring(0, 26)}...` : simplifiedUrl

        // If the url finish with "/" remove it
        simplifiedUrl = simplifiedUrl && simplifiedUrl.endsWith('/') ? simplifiedUrl.slice(0, simplifiedUrl.length - 1) : simplifiedUrl


        return <a key={`link-${j}-${i}`} href={!urlHasProtocol ? "https://" + url : url} title={url} rel="noopener noreferrer" target='_blank'>{simplifiedUrl}</a>
      })

    } catch (e) {
      console.error(e)
      return url
    }
  }

  handleMediaError(e) {
    if (!(e && e.currentTarget)) return
    e.currentTarget.classList.add('error')
  }

  transformLink(message, network) {
    // #hashtag become links
    message = StringReplace(message, RegExp(/#(\S+\b)/ig), (match, i) => {

      if (!network) return <span key={"#" + i + match} className="hashtag">#{match}</span>

      const url = network === 'twitter' ? 'https://twitter.com/hashtag/' + match + '?src=hash' : `https://www.linkedin.com/feed/hashtag/?keywords=${match}`

      return <a key={"#" + i + match} title={url} rel="noopener noreferrer" target='_blank'
        href={url}>#{match}</a>
    })

    // @screenName become links
    message = StringReplace(message, RegExp(HANDLE_REGEX), (match2, i) => {

      if (network === 'twitter') {
        const url = 'https://twitter.com/' + match2
        return <a key={"@" + i + match2} title={url} rel="noopener noreferrer" target='_blank'
          href={url}>@{match2}</a>

      } else if (network === 'linkedin' || !network) {
        return <span key={"@" + i} className="handle">@{match2}</span>
      }
    })

    return message
  }

  /** retrieve media properties */

  retrieveMediaPropertiesTwitter(post) {
    let media = null
    if (post.extended_entities && 'media' in post.extended_entities && post.extended_entities.media.length) {
      media = { native: post.extended_entities.media.length > 1 ? params.mediaTypes.IMAGE : post.extended_entities.media[0].type === 'photo' ? params.mediaTypes.IMAGE : params.mediaTypes.VIDEO }
      media.poster = media.native === params.mediaTypes.VIDEO ? post.extended_entities.media[0].media_url_https : null
      media.url = media.native === params.mediaTypes.IMAGE ? post.extended_entities.media.map((media) => media.media_url_https) : (post.extended_entities.media[0].video_info.variants.filter(s => s.content_type === 'video/mp4')).sort((a, b) => b.bitrate - a.bitrate).map((v, i) => i === 0 ? v.url : null)
    }
    return media
  }

  retrieveMediaPropertiesFacebook(post) {
    let media = null
    const image = (post.image_uploadcare && post.image_uploadcare.url && (post.image_uploadcare.url !== 'https://ucarecdn.com/undefined/') ? post.image_uploadcare.url : null) || this.state.image_upload_care || post.image

    if (image && !post.video) media = { native: "image", url: [image] }
    // currently videos's url retuned by facebook have expired
    else if (post.video) media = { native: "video", url: [post.video] }
    return media
  }

  retrieveMediaPropertiesLinkedin(post) {
    let media = null

    // if type is image and there are images
    if (post.media && post.media.type === "image" && post.media.images && post.media.images.length) {
      media = { native: "image", url: post.media.images }
    }

    // if type is video
    else if (post.media && post.media.type === "video") {
      media = { native: "video", url: [post.media.video] }
    }
    return media
  }

  retrieveMediaPropertiesInstagram(post) {
    let media = null
    let image = (post.image_uploadcare && post.image_uploadcare.url && (post.image_uploadcare.url !== 'https://ucarecdn.com/undefined/') ? post.image_uploadcare.url : null) || this.state.image_upload_care || post.image_high_resolution_url || post.image_standard_resolution_url || post.image_low_resolution_url || ' '

    // temporary we use thumbnail for video
    if (post.type === "video" && image) {
      media = { native: "thumbnail", url: [image] }
    }

    else if ((post.type === "image" || post.type === "sidecar") && image) {
      media = { native: "image", url: [image] }
    }

    return media
  }

  /** render media */

  renderMedia(media) {
    if (media && media.native === 'image') {
      if (media.url.length === 3) {
        return (
          <>
            <div className="flux-card__content-media-container">
              <div className="flux-card__content-media-wrapper">
                <img alt="media" className="flux-card__content-media-picture js__interactive-media" src={media.url[0]} onClick={() => this.props.enlargeMedia(media, 0)} onError={this.handleMediaError} />
              </div>
            </div>
            <div className="flux-card__content-media-container">
              <div className="flux-card__content-media-wrapper">
                <img alt="media" className="flux-card__content-media-picture small js__interactive-media" src={media.url[1]} onClick={() => this.props.enlargeMedia(media, 1)} />
              </div>
              <div className="flux-card__content-media-wrapper">
                <img alt="media" className="flux-card__content-media-picture small js__interactive-media" src={media.url[2]} onClick={() => this.props.enlargeMedia(media, 2)} />
              </div>
            </div>
          </>
        )
      } else {
        const isInstaOrFb = ['facebook', 'instagram'].includes(this.props.account.network)

        // we show only 4 images in a grid
        return media.url.slice(0, 4).map((m, index) => {
          return (
            <div className={`flux-card__content-media-wrapper ${media.url.length > 1 ? 'half' : ''}`} key={index}>
              {isInstaOrFb && <a href={this.props.post.link} target="_blank" rel="noopener noreferrer" className="flux-card__content-media-error text">IMG</a>}
              <img alt="media" className={`flux-card__content-media-picture ${media.url.length >= 4 ? 'small' : ''} ${isInstaOrFb ? 'absolute' : ''} js__interactive-media`} src={media.url[index]} onClick={() => this.props.enlargeMedia(media, index)} onError={this.handleMediaError} />
            </div>
          )
        })
      }
    }

    else if (media && media.native === 'video') {
      const poster = (this.props.account.network === 'facebook') && this.props.post.image_uploadcare && this.props.post.image_uploadcare.url && (this.props.post.image_uploadcare.url !== 'https://ucarecdn.com/undefined/') ? this.props.post.image_uploadcare.url : null
      return (
        <>
          <div
            className="flux-card__content-media-error no-bg"
          >
            {poster ?
              <img src={poster} alt="thumbnail" />
              : ''}
          </div>
          <video poster={media.poster ? media.poster : poster ? poster : null} className="flux-card__content-media-video js__interactive-media" src={media.url[0] + "#t=0.001"} onError={this.handleMediaError} onClick={() => this.props.enlargeMedia(media, 0)}></video>
          <PlayShadowIcon />
        </>
      )
    }

    else if (media && media.native === 'thumbnail') {

      let postUrl = this.props.account.network === "instagram" ? this.props.post.link : null

      return (
        <a href={postUrl} target="_blank" rel="noopener noreferrer" className="flux-card__content-media-thumbnail">
          <div className="flux-card__content-media-error"></div>
          <img alt="media" className={`flux-card__content-media-picture js__interactive-media`} src={media.url[0]} onError={this.handleMediaError} />
          <PlayShadowIcon />
        </a>
      )
    }

  }

  renderQuoteRetweet(_post) {
    if (!_post || (!_post.quoted_status && !_post.retweeted_status)) return ''

    const post = _post.quoted_status || _post.retweeted_status
    const media = this.retrieveMediaPropertiesTwitter(post)

    return (
      <div className="flux-card__content-quote">
        <div className="flux-card__content-quote-displayname">
          {post.user.name}
        </div>
        <div className="flux-card__content-quote-screenname">
          @{post.user.screen_name}
        </div>
        <div className="flux-card__content-quote-message">
          {this.renderMessageTwitter(post)}
        </div>

        {media &&
          <div className="flux-card__content-quote-media">
            {this.renderMedia(media)}
          </div>
        }
      </div>
    )
  }

  renderQuoteLinkedin(_post) {
    if (!_post || !_post.shared_post) return ''

    const post = _post.shared_post

    const media = this.retrieveMediaPropertiesLinkedin(post)

    return (
      <div className="flux-card__content-quote">
        <img src={post.author_image || defaultUserIcon} alt="" className="flux-card__content-quote-avatar" />
        <div className="flux-card__content-quote-screenname">
          {post.author_name}
        </div>
        <div className="flux-card__content-quote-message">
          {this.renderMessageLinkedin(post)}
        </div>

        {media &&
          <div className="flux-card__content-quote-media">
            {this.renderMedia(media)}
          </div>
        }
      </div>
    )
  }

  // render message
  renderMessageInstagram(post) {
    return post.caption
  }

  renderMessageFacebook(post) {
    return post.post_text || post.text
  }

  renderMessageLinkedin(post) {
    return post.text
  }

  renderMessageTwitter(post) {
    let message = post.full_text
    const quoteLink = post.quoted_status_permalink && post.quoted_status_permalink.url

    message = post.display_text_range && post.display_text_range.length && message ? ([...message].slice(post.display_text_range[0], post.display_text_range[1])).join('') : message

    if (post && post.entities && post.entities.urls && post.entities.urls.length) {
      for (let url of post.entities.urls) {

        // Replace shorted url by display url. If there is the link of the quote, remove it
        message = quoteLink === url.url ? message.replace(url.url, '') : message.replace(url.url, url.expanded_url)
      }
    }

    // Retrieve url with or without protocol
    const regexUrl = /(\S+\.[^\s]+(\/\S+|\/|))/g

    let urls = (typeof (message) === 'string') && message.match(regexUrl)

    if (urls) {
      let j = 0
      for (let url of urls) {
        message = this.buildLink(message, url, j)
        j++
      }
    }

    message = this.transformLink(message, 'twitter')


    return message
  }

  renderOpenGraph() {

    if (!this.state.open_graph || !(this.state.open_graph && this.state.open_graph.image && this.state.open_graph.title && this.state.open_graph.url)) return ''

    return (
      <div className="flux-card__content-preview">
        {this.state.open_graph.image && <div className="flux-card__content-preview-image">
          <img src={this.state.open_graph.image} alt="" />
        </div>}
        {this.state.open_graph.title && <p className="flux-card__content-preview-title">{this.state.open_graph.title}</p>}
        {this.state.open_graph.url && <p className="flux-card__content-preview-url">{this.state.open_graph.url}</p>}
      </div>
    )

  }

  render() {
    const post = this.props.post
    const network = this.props.account.network
    const isRetweet = !!post.retweeted_status

    const message = network === "twitter" ? this.renderMessageTwitter(post) :
      network === "facebook" ? this.renderMessageFacebook(post) :
        network === "linkedin" ? this.renderMessageLinkedin(post) :
          network === "instagram" ? this.renderMessageInstagram(post) : ""

    const media = network === "twitter" ? this.retrieveMediaPropertiesTwitter(post) :
      network === "facebook" ? this.retrieveMediaPropertiesFacebook(post) :
        network === "linkedin" ? this.retrieveMediaPropertiesLinkedin(post) :
          network === "instagram" ? this.retrieveMediaPropertiesInstagram(post) : ""

    return (
      <div className="flux-card__content">
        {!isRetweet &&
          <div className="flux-card__content-message">
            {message}
          </div>
        }

        {(media && (network !== "twitter" || (network === "twitter" && !isRetweet))) &&
          <div className="flux-card__content-media">
            {
              this.renderMedia(media)
            }
          </div>
        }

        {isRetweet && <img src={IconRetweet} alt="rt" />}
        {
          network === "twitter" ? this.renderQuoteRetweet(post) :
            network === "linkedin" ? this.renderQuoteLinkedin(post) : ""
        }

        {!media && this.renderOpenGraph()}
      </div>
    )
  }
}

export default FluxCardContent