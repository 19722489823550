import React from 'react'

const IconLeaderboard = () => (
  <svg width="24" height="24" className="icon__leaderboard" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 15C15.866 15 19 11.866 19 8C19 4.13401 15.866 1 12 1C8.13401 1 5 4.13401 5 8C5 11.866 8.13401 15 12 15Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8.21 13.89L7 23L12 20L17 23L15.79 13.88" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export default IconLeaderboard
